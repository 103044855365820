import { navigate } from "gatsby"
import React from "react"
import Icon from "../../Icon"
import { VideoContainer } from "../VideoContainer/VideoContainer"
import * as styles from "./MediaFullScreen.module.scss"

interface MediaFullScreenProps {
  url: string
  title: string
  type: string
  goBack(): void
}

export const MediaFullScreen = ({
  url,
  title,
  type,
  goBack,
}: MediaFullScreenProps) => {
  return (
    <div className={styles.mediaFullScreenContainer}>
      <div className={styles.mediaFullScreenInner}>
        {type === "video" && <VideoContainer src={url} title={title} />}
        {type === "image" && (
          <div className={styles.mediaFullScreenImageContainer}>
            <img src={url} alt={title} />
          </div>
        )}
        <button
          className={styles.mediaFullScreengoBackButton}
          onClick={() => goBack()}
        >
          <Icon name="closeSquare" />
        </button>
      </div>
    </div>
  )
}

// extracted by mini-css-extract-plugin
export var artworkActionsButtons = "Artwork-module--artworkActionsButtons--b5821";
export var artworkContainer = "Artwork-module--artworkContainer--779e8";
export var artworkContract = "Artwork-module--artworkContract--0f27e";
export var artworkContractAddress = "Artwork-module--artworkContractAddress--148c9";
export var artworkContractContainer = "Artwork-module--artworkContractContainer--9ecdc";
export var artworkDescriptionDetails = "Artwork-module--artworkDescriptionDetails--de854";
export var artworkDescriptionTitle = "Artwork-module--artworkDescriptionTitle--930e6";
export var artworkDetail = "Artwork-module--artworkDetail--04285";
export var artworkDetailDescription = "Artwork-module--artworkDetailDescription--d38c6";
export var artworkDetailTable = "Artwork-module--artworkDetailTable--34e7d";
export var artworkMedia = "Artwork-module--artworkMedia--9c5b1";
export var artworkNftItem = "Artwork-module--artworkNftItem--efcf4";
export var artworkNftTypes = "Artwork-module--artworkNftTypes--8ae0c";
export var artworkNftTypesHeader = "Artwork-module--artworkNftTypesHeader--f928c";
export var artworkPurchaseHistory = "Artwork-module--artworkPurchaseHistory--0b19a";
export var artworkStartingPrice = "Artwork-module--artworkStartingPrice--38713";
export var descriptionMarkdown = "Artwork-module--descriptionMarkdown--8bab7";
export var dogood = "Artwork-module--dogood--c42b2";
export var experience = "Artwork-module--experience--5dbc7";
export var finearts = "Artwork-module--finearts--14dfb";
export var luxe = "Artwork-module--luxe--21e27";
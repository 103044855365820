import { graphql, Link, PageProps } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import { ContentfulLotQuery } from "../../../types/gatsby-graphql"
import { MediaFullScreen } from "../../components/MediaFullScreen/MediaFullScreen"
import * as styles from "./Artwork.module.scss"
import ReactMarkdown from "react-markdown"
import { VideoContainer } from "../../components/VideoContainer/VideoContainer"
import Loadable from "@loadable/component"

const Artwork = ({ data }: PageProps<ContentfulLotQuery>) => {
  const [showFullScreen, setShowFullScreen] = useState(false)
  const [width, setWidth] = useState<number | null>()
  const isMobile = width && width < 769

  const parsedData = useMemo(() => {
    return data.contentfulLot
  }, [data])

  useEffect(() => {
    setWidth(window.innerWidth)
    const handleSize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleSize)
    return () => {
      window.removeEventListener("resize", handleSize)
    }
  }, [width])

  if (showFullScreen) {
    return (
      <MediaFullScreen
        title={parsedData?.auction?.name!}
        url={parsedData?.auction?.mint?.mediaAsset?.media?.file?.url!}
        type={
          parsedData?.auction?.mint?.mediaAsset?.media?.mimeType?.indexOf(
            "image"
          ) === 0
            ? "image"
            : "video"
        }
        goBack={() => setShowFullScreen(false)}
      />
    )
  }

  const LoadableAuctionMonitor = Loadable(
    () => import("../../components/AuctionMonitor/AuctionMonitor")
  )

  const artist = [
    data.contentfulLot?.artist?.firstName,
    data.contentfulLot?.artist?.middleName,
    data.contentfulLot?.artist?.lastName,
  ]

  return (
    <LoadableAuctionMonitor
      auctionId={parsedData?.auction?.contentfulid!}
      render={({ data: auction }: any) => {
        const enrichedData = {
          lot: parsedData,
          auction,
        }

        const isExternal = !!enrichedData?.lot?.externalSiteUrl

        return (
          <div className={styles.artworkContainer}>
            <div className={styles.artworkMedia}>
              {enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.mimeType.indexOf(
                "video"
              ) === 0 ? (
                <VideoContainer
                  src={
                    enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.file
                      ?.url!
                  }
                  title={
                    enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.title!
                  }
                />
              ) : (
                <img
                  src={
                    enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.file
                      ?.url!
                  }
                  alt={
                    enrichedData?.lot?.auction?.mint?.mediaAsset?.media?.title!
                  }
                />
              )}
            </div>
            <div className={styles.artworkDetail}>
              <h1>{enrichedData?.auction?.name}</h1>
              <div className={styles.artworkActionsButtons}>
                {enrichedData?.lot?.externalSiteUrl ? (
                  <a
                    href={enrichedData?.lot?.externalSiteUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                ) : (
                  <Link
                    to={`/artwork/collect/${enrichedData?.lot?.contentful_id}`}
                  >
                    Collect
                  </Link>
                )}
                {/* <button onClick={() => setShowFullScreen(true)}>
                  View all resolution
                </button> */}
              </div>
              <div className={styles.artworkDetailTable}>
                <div>
                  <div>Artist:</div>
                  <div>{artist.join(" ")}</div>
                </div>
                <div>
                  <div>Collection:</div>
                  <div>{enrichedData?.lot?.sale?.[0]?.name}</div>
                </div>
                {/* <div>
                  <div className={styles.artworkNftTypesHeader}>Type:</div>
                  <div className={styles.artworkNftTypes}>
                    {enrichedData?.lot?.sale?.[0]?.nftType?.map((nType: string | null) => {
                      if ( !nType ) return null
                      return (
                        <div className={styles.artworkNftItem}>
                          {nType.toLowerCase() === 'luxe nft' && (
                            <LuxeNFT className={styles.luxe}/>
                          )}
                          {nType.toLowerCase() === 'experience nft' && (
                            <ExperienceNFT className={styles.experience}/>
                          )}
                          {nType.toLowerCase() === 'do good nft' && (
                            <DoGoodNFT className={styles.dogood}/>
                          )}
                          {nType.toLowerCase() === 'fine arts nft' && (
                            <FineArtsNFT className={styles.finearts}/>
                          )}
                          <span>{nType}</span>
                        </div>
                      )
                    })}
                  </div>
                </div> */}
                {isExternal ? null : (
                  <>
                    <div>
                      <div>
                        {enrichedData?.auction?.type === "Traditional"
                          ? "Starting Price"
                          : "Price"}
                        :
                      </div>
                      <div className={styles.artworkStartingPrice}>
                        {enrichedData?.auction?.reservePriceInETH!.toFixed(2)}{" "}
                        ETH
                      </div>
                    </div>
                    <div>
                      <div>Edition:</div>
                      <div>
                        {enrichedData?.auction?.quantity} /{" "}
                        {enrichedData?.auction?.nft?.quantity}
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.artworkDetailDescription}>
                  <div className={styles.artworkDescriptionTitle}>
                    About the Artwork
                  </div>
                  <div
                    className={styles.artworkDescriptionDetails}
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <ReactMarkdown className={styles.descriptionMarkdown}>
                      {enrichedData?.lot?.auction?.mint?.description
                        ?.description || ""}
                    </ReactMarkdown>
                  </div>
                  {!isMobile && !isExternal && (
                    <div className={styles.artworkContractContainer}>
                      <div>Contract</div>
                      <div className={styles.artworkContractAddress}>
                        {enrichedData?.lot?.auction?.contentfulid}
                      </div>
                    </div>
                  )}
                </div>
                {isMobile && !isExternal && (
                  <div className={styles.artworkContract}>
                    <div>Contract</div>
                    <div className={styles.artworkContractAddress}>
                      {enrichedData?.lot?.auction?.contentfulid}
                    </div>
                  </div>
                )}
                {/* <div className={styles.artworkPurchaseHistory}>
                  <div>
                    <p>Purchase History</p>
                  </div>
                  <div>
                    <p>View All History +</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Artwork

export const artworkQuery = graphql`
  query ContentfulLot($contentful_id: String!) {
    contentfulLot(contentful_id: { eq: $contentful_id }) {
      contentful_id
      ended
      lotId
      externalSiteUrl
      auction {
        id
        contentfulid
        contentful_id
        maximumIncrease
        reservePriceInEth
        quantity
        percentageIncrease
        status
        type
        name
        mint {
          tokenId
          name
          description {
            description
          }
          contentfulid
          contentful_id
          mediaAsset {
            media {
              file {
                url
              }
              title
              mimeType
              description
            }
            main
          }
          id
          artist
          auction {
            quantity
            reservePriceInEth
            percentageIncrease
            maximumIncrease
          }
        }
      }
      sale {
        name
        nftType
      }
      artist {
        username
        middleName
        lastName
        firstName
        walletAddress
      }
    }
  }
`
//
//   < div
// className = { styles.artworkContainer } >
// <div className={styles.artworkMedia}>
// <button onClick={() => setShowFullScreen(true)}>
// <img
// src={enrichedData?.asset?.mainMedia?.file?.url!}
// alt={enrichedData?.asset?.title!}
// />
// </button>
// </div>
// <div className={styles.artworkDetail}>
//   <h1>{enrichedData?.asset?.title}</h1>
//   <div className={styles.artworkDetailInner}>
//     <span className={styles.artworkDetailHeaders}>Artist:</span>
//     <span className={styles.subtitle}>
//               {enrichedData?.asset?.artist?.name}
//             </span>
//     <span className={styles.artworkDetailHeaders}>Collection:</span>
//     <span className={styles.content}>{auction.subTitle}</span>
//     <span className={styles.artworkDetailHeaders}>Minted on:</span>
//     <div className={styles.mintedInfo}>
//       <span className={styles.mintedInfoHeader}>Minted on:</span>
//       <img src={EthereumIcon} alt="ETH" />
//       <span>{auction.mintedOn}</span>
//     </div>
//     <span className={styles.artworkDetailHeaders}>Minted on:</span>
//     <span className={styles.content}>{auction.includes}</span>
//     <span className={styles.artworkDetailHeaders}>Minted on:</span>
//     <span className={styles.shipmentInfo}>{auction.shipped}</span>
//     <span className={styles.artworkDetailHeaders}>Edition:</span>
//     <span className={styles.content}>{auction.edition}</span>
//     <span className={styles.artworkDetailHeaders}>Starting Price:</span>
//     <span className={styles.content}>{`$${auction.price?.toFixed(
//       2
//     )} USD`}</span>
//   </div>
// </div>
// <div className={styles.artworkAditionalInfo}>
//   <div className={styles.artworkAbout}>
//     <h1>About the Artwork</h1>
//     <span>{enrichedData?.asset?.description?.description}</span>
//   </div>
//   <div className={styles.artworkSpecs}>
//     <div className={styles.artworkSpecsInfo}>
//               <span>
//                 {enrichedData?.asset?.mainMedia?.file?.contentType}
//               </span>
//       <span>{`${enrichedData?.asset?.mainMedia?.width} x ${enrichedData?.asset?.mainMedia?.height} pixels`}</span>
//     </div>
//     <div className={styles.artworkSpecsContract}>
//       <span>Contract:</span>
//       <span>{enrichedData?.asset?.contractAddress}</span>
//     </div>
//   </div>
// </div>
// <div className={styles.artworkFeaturedArtists}>
//   <h2>Collected by:</h2>
//   <div className={styles.artworkFeaturedArtistsInner}>
//     {auction.featuredArtists?.map(artist => {
//       return (
//         <ProfileCard
//           name={artist.name}
//           edition={Number.parseInt(artist.edition)}
//           avatar={artist.avatar}
//           account={artist.account}
//         />
//       )
//     })}
//   </div>
// </div>
// <div className={styles.artworkPurchaseHistory}>
//   <div className={styles.artworkPurchaseHistoryInner}>
//     <h1>Purchase History</h1>
//     <Link to="#">
//       <h2>View All History +</h2>
//     </Link>
//   </div>
//   {auction.purchaseHistory?.map(history => {
//     return (
//       <div className={styles.artworkPurchaseHistoryInner}>
//         <div>
//                   <span>{`Purchased By ${shortAddress(
//                     history.purchasedBy
//                   )}`}</span>
//           <span>{`${moment(history.date).format(
//             "YYYY-MM-DD h:mm:ss"
//           )}`}</span>
//         </div>
//         <span>{`$${history.price.toLocaleString(undefined, {
//           maximumFractionDigits: 2,
//         })}`}</span>
//       </div>
//     )
//   })}
// </div>
// <div className={styles.artworkTagsContainer}>
//   <h1>Tags: </h1>
//   <div className={styles.artworkTags}>
//     {auction.tags?.map(tag => {
//       return <Tag title={tag} />
//     })}
//   </div>
// </div>
// </div>
// )
